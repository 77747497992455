/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Box} from '@chakra-ui/react';
import {Gist} from 'mdx-embed';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    blockquote: "blockquote",
    p: "p",
    code: "code",
    strong: "strong",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "MacOS Common Action CheatSheet"), "\n", React.createElement(_components.h3, null, "Set hidden files in Finder to \"show\" or \"hide\""), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "💡 For changes to take effect restart or ", React.createElement(_components.code, null, "killall Finder")), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Show hidden files")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "defaults write com.apple.finder AppleShowAllFiles TRUE\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Hide hidden files")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "defaults write com.apple.finder AppleShowAllFiles FALSE\n")), "\n", React.createElement(_components.h3, null, "Increase Mouse/trackpad tracking speed"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "See current speed")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "defaults read -g com.apple.mouse.scaling\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Edit speed")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "defaults write -g com.apple.mouse.scaling 8.0\n")), "\n", React.createElement(_components.h2, null, "Macos Starter Script 2022"), "\n", React.createElement(Box, {
    as: Gist,
    mb: 6,
    gistLink: "LucasZapico/d6751e5ed72ef5c5dd5bcfe9f364130f"
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "gist:LucasZapico/d6751e5ed72ef5c5dd5bcfe9f364130f#macos-starter-script-2022.sh")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
